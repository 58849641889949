$primary: #0b2751;
$success: #00B72C;

* {
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}


//? Add Here custom overrides
.carousel-control-prev, .carousel-control-next {
  width: 5% !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.navbar-index {
  z-index: 99999999;
  background-color: white;
}

.overlay-bg-1::before {
  content: '';
  background-color: rgba(11, 39, 81, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.content-index {
  position: relative !important;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
}


.overlay-bg-2::before {
  content: '';
  background-color: rgba(11, 39, 81, 0.85);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

//? FONTS
.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.video-style {
  width: 700px;
}

.logo-style {
  width: 350px !important; 
}

.width-custom {
  width: 75%;
}

.width-compentence {
  width: 50%;
}

.container-width {
  width: inherit;
}

@media (max-width: 550px) {
  .video-style {
    width: 400px;
  }

  .logo-style {
    width: 120px !important; 
  }

  .width-custom, .width-compentence {
    width: 100%;
  }

  .container-width {
    width: 95% !important;
  }
  
}

@media (max-width: 850px) {
  .logo-style {
    width: 120px !important; 
  }

  .width-custom, .width-compentence {
    width: 100%;
  }

  .container-width {
    width: 95% !important;
  }
  
}


@import '../../node_modules/bootstrap/scss/bootstrap.scss'